<template>
    <div class="contenedor" :class="[$mq]">
        <template v-if="isCentral && !editing">
            <div class="post-as" :class="{ central: post == 2, selected: selected, hasImage: compImage != '' }" :style="{ backgroundImage: 'url(' + employee.avatar + ')' }">
                <span>{{ $t('notes.post_as') }}</span>
                <div class="select-container">
                    <v-select v-if="Object.values(postOptions).length > 0" hide-details class="select" v-model="post" :items="Object.values(postOptions)" item-text="name" item-value="id" dense :no-data-text="$t('supervise.filter.no_results')"></v-select>
                </div>
            </div>
        </template>
        <div class="new" :class="{ active: selected }">
            <div class="error" v-if="this.error">{{ this.error }}</div>

            <template v-if="selected && selected.type == 2">
                <div class="reply">
                    <div class="content-reply">
                        <div class="employee">
                            {{ locationData.account_name }}
                        </div>
                        <div class="message" v-html="selected.message"></div>
                    </div>
                    <div class="close" @click="unselect()"></div>
                </div>
            </template>
            <template v-else-if="selected">
                <div class="reply">
                    <div class="content-reply">
                        <div class="employee">
                            {{ employees[selected.employee] ? employees[selected.employee].name : $t('notes.user_unknown') }}
                            {{ employees[selected.employee] ? employees[selected.employee].surname : '' }}:
                        </div>
                        <div class="message" v-if="selected.message != ''" v-html="selected.message"></div>
                        <div class="message" v-else>{{ $t('notes.only_image_reply') }}</div>
                    </div>
                    <div class="close" @click="unselect()"></div>
                </div>
            </template>
            <template v-if="editing">
                <div class="editing">
                    <div class="message">{{ $t('notes.edit_message') }}</div>
                    <div class="close" @click="unEdit()"></div>
                </div>
            </template>
            <template v-if="compImage != ''">
                <div class="attachment-reply">
                    <div class="message">
                        <div v-if="compImage != ''" @click.stop="preview()" class="img-container">
                            <!-- <cld-image
              class="image"
              :publicId="compImage"
              type="upload"
            >
              <cld-transformation
                height="40"
                radius="3"
                crop="fill"
                fetchFormat="auto"
              />
            </cld-image> -->
                            <div
                                class="img"
                                :style="{
                                    backgroundImage: 'url(' + (compImage ? compImage : '') + ')',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center'
                                }"
                            ></div>
                            <div class="delete" @click="imageDelete()"></div>
                        </div>
                    </div>
                </div>
            </template>

            <div class="meta">
                <div class="attachment" @click="capture($event)">
                    <input type="file" ref="fileLogbook" name="change-image" v-if="uploadReady" class="input-file" accept="image/*" capture="camera" @change="imageReload" />
                </div>

                <!-- <v-textarea class="message" ref="message" v-model="messageText" auto-grow flat dense outlined rows="1" counter="240" solo single-line hide-details :placeholder="$t('notes.new_note')" style="margin: 0; padding: 0"> </v-textarea> -->
                <textarea class="message" maxlength="4096" :placeholder="$t('notes.new_note')" id="message" ref="message" v-model="messageText"></textarea>

                <div class="close" @click="reset()"></div>
                <div class="send" @click="send()"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Reply',
    props: {
        note: { default: false },
        isCentral: { default: false }
    },
    components: {},
    data() {
        return {
            date: moment(),
            messageText: '',
            image: '',
            error: '',
            compImage: '',
            uploadReady: true,
            post: 1
        }
    },
    computed: {
        postOptions() {
            var result = []
            if (this.organization && this.employee) {
                const roles = this.$store.getters['login/getRoles']
                const user = this.$store.getters['loginUser/getUserInformation']

                if (user.rol && typeof roles[user.rol] !== 'undefined' && parseInt(roles[user.rol].level) >= 6) {
                    result.push({ name: this.organization.account ? this.locationData.name : this.locationData.account_name, id: 2 })
                }
                result.push({ name: this.employee.name + ' ' + this.employee.surname, id: 1 })
            }
            return result
        },
        organization() {
            return this.$store.getters['notes/getLocationFiltered']
        },
        employee() {
            var employeeId = this.$store.getters['loginUser/getLocalEmployee']
            log(employeeId)
            if (employeeId && this.employees) {
                return this.employees[employeeId]
            }
            return false
        },
        parent() {
            return this.$parent
        },
        locationData() {
            return this.$store.getters['loginUser/getLocalLocation']
        },
        employees() {
            return this.$store.getters['employee/getAllEmployees']
        },

        selected() {
            var selected = this.$store.getters['notes/getSelected']
            if (selected) {
                this.$emit('editingWithoutImage', true)
                return { ...selected }
            }
            this.$emit('editingWithoutImage', false)
            return false
        },
        editing() {
            var editing = this.$store.getters['notes/getEditing']
            if (editing) {
                this.messageText = editing.message
                if (editing.image != null) {
                    this.compImage = editing.image
                    this.$emit('editingWithImage', true)
                } else {
                    this.$emit('editingWithImage', false)
                    this.$emit('editingWithoutImage', true)
                }
                return { ...editing }
            }
            this.$emit('editingWithImage', false)
            this.$emit('editingWithoutImage', false)

            return false
        }
    },
    methods: {
        refresh() {
            this.parent.refreshNotes()
        },
        preview() {
            log('preview 1')
            if (this.note.image) {
                log('preview 2')
                this.$popup.media({ image: this.note.image })
            }
        },
        removeImage() {
            this.uploadReady = false
            this.$nextTick(() => {
                this.uploadReady = true
            })
        },
        reset() {
            this.messageText = ''
            // var el = document.getElementById('message')
            // this.autoResize(el)
        },

        unselect() {
            this.$store.commit('notes/select', false)
            this.messageText = ''
            this.compImage = ''
        },
        unEdit() {
            this.$store.commit('notes/edit', false)
            this.messageText = ''
            this.compImage = ''
        },

        send() {
            var self = this

            this.error = ''
            var addImage = false
            var validation = []
            var oData = {}
            var image = ''

            oData.employee = this.emp_id
            oData.parent_id = this.selected ? this.selected.id : false
            oData.location_id = this.organization ? this.organization : null
            oData.msg = this.messageText

            oData.image = this.compImage ? this.compImage : ''

            if (!fnCheckConnection()) {
                this.$overlay.hide()
                this.$popup.alert({
                    closable: false,
                    blocking: false,
                    message: self.$t('popup.chat.offline_send'),
                    textSave: self.$t('popup.audits.exit'),
                    callSave: function (response) {
                        self.$popup.close()
                    }
                })
                return
            }
            if (this.isCentral) {
                oData.type = this.post
            }

            if (typeof oData.image !== 'undefined' && oData.image !== null && oData.image !== 'null') {
                if (oData.image.indexOf('data:') != -1 && oData.image.indexOf(';base64,') != -1) {
                    addImage = true
                }
            }
            if ((oData.msg.trim() == '' && oData.image == '') || oData.msg.trim().length > 4096) {
                validation.push(false)
                this.error = this.$t('notes.message_validation')
            }

            if (validation.indexOf(false) == -1) {
                if (addImage) {
                    image = oData.image
                    oData.image = ''
                }

                if (!this.editing) {
                    self.$overlay.loading()
                    this.$store.dispatch('notes/addLog', { ...oData }).then(function (response) {
                        var sel = false
                        var sel = self.selected
                        self.resetValues()
                        if (addImage) {
                            self.attachImage(self, response.data, image)
                        }
                        self.compImage = ''
                        self.removeImage()
                        if (sel) {
                            self.parent.refreshNotes(true)
                        } else {
                            self.parent.refreshNotes()
                        }
                    })
                } else if (this.editing) {
                    self.$overlay.loading()
                    oData.id = this.editing.id
                    this.$store.dispatch('notes/editLog', { ...oData }).then(function (response) {
                        self.resetValues()
                        if (addImage) {
                            self.attachImage(self, oData.id, image)
                        }
                        self.compImage = ''
                        self.removeImage()
                        self.parent.refreshNotes(true)
                    })
                }
            }
        },
        attachImage(self, id, image, goDetail) {
            var oData = { id: id, image: image }
            self.$store.dispatch('notes/attachImage', oData).then(function (response) {
                if (goDetail) {
                    self.goToDetail()
                } else {
                    self.refresh()
                }
            })
        },
        resetValues() {
            this.messageText = ''
            this.image = ''
            this.error = ''
            this.$store.commit('notes/select', false)
            this.$store.commit('notes/edit', false)
        },
        imagePreview() {
            if (this.compImage && !this.editing) {
                this.$popup.media({ image: this.compImage })
            } else {
                this.capture()
            }
        },
        imageDelete() {
            let self = this
            this.$popup.confirm({
                message: self.$t('popup.delete.title'),
                textSave: self.$t('popup.delete.text_save'),
                textCancel: self.$t('popup.delete.text_cancel'),
                callSave: function () {
                    self.compImage = false
                    // var el = document.getElementById('message')
                    // self.autoResize(el)
                    self.$refs.fileLogbook.value = null
                }
            })
        },
        imageReload(e) {
            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return

            var image = new Image()
            var reader = new FileReader()
            var self = this

            reader.onload = (e) => {
                self.compImage = e.target.result
                // var el = document.getElementById('message')
                // self.autoResize(el)
            }
            reader.readAsDataURL(files[0])
            this.error = ''
        },
        captureNativeGallery(type) {
            var self = this
            var input = document.createElement('input')
            input.type = 'file'
            input.accept = 'image/*'
            input.capture = 'camera'

            input.onchange = function (ev) {
                const file = ev.target.files[0] // get the file
                const blobURL = URL.createObjectURL(file)
                const img = new Image()
                img.src = blobURL
                img.onerror = function () {
                    URL.revokeObjectURL(this.src)
                    // Handle the failure properly
                    self.$snackbar.error({ closeable: true, message: self.$t('popup.media.image_empty') })
                }
                img.onload = function () {
                    URL.revokeObjectURL(this.src)
                    const [newWidth, newHeight] = calculateImgSize(img, andyImageLimit.width, andyImageLimit.height)
                    const canvas = document.createElement('canvas')
                    canvas.width = newWidth
                    canvas.height = newHeight
                    const ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0, newWidth, newHeight)
                    canvas.toBlob(
                        (blob) => {
                            // Handle the compressed image. es. upload or save in local state
                            displayFileInfo('Original file', file)
                            displayFileInfo('Compressed file', blob)
                            var reader = new FileReader()
                            reader.readAsDataURL(blob)
                            reader.onloadend = function () {
                                var base64data = reader.result
                                log('base64 image:', base64data)
                                self.compImage = base64data

                                self.$store.commit('setCameraActive', false)
                            }
                        },
                        andyImageLimit.mime,
                        andyImageLimit.quality
                    )
                }
            }

            input.click()
        },
        captureNative() {
            var self = this
            this.$overlay.loading()
            this.$store.commit('setCameraActive', true)
            var imageCapture
            var track
            var currentCamera = 'back'
            var flash = false

            setTimeout(function () {
                navigator.mediaDevices
                    .getUserMedia({ video: { facingMode: { exact: 'environment' } } })
                    .then((mediaStream) => {
                        track = mediaStream.getVideoTracks()[0]
                        imageCapture = new ImageCapture(track)
                        document.querySelector('video').srcObject = mediaStream
                    })
                    .catch((error) => console.log(error))

                document.getElementById('swap-camera').addEventListener('click', function (e) {
                    log('swap camera')
                    e.preventDefault()
                    e.stopPropagation()

                    track.stop()

                    if (currentCamera == 'front') {
                        navigator.mediaDevices
                            .getUserMedia({ video: { facingMode: { exact: 'environment' } } })
                            .then((mediaStream) => {
                                track = mediaStream.getVideoTracks()[0]
                                imageCapture = new ImageCapture(track)
                                document.querySelector('video').srcObject = mediaStream
                            })
                            .catch((error) => console.log(error))
                    } else {
                        if (flash) {
                            track.applyConstraints({
                                advanced: [{ torch: !flash }]
                            })
                            flash = !flash
                            document.getElementById('flash-camera').classList.remove('bounce')
                        }

                        navigator.mediaDevices
                            .getUserMedia({ video: true })
                            .then((mediaStream) => {
                                track = mediaStream.getVideoTracks()[0]
                                imageCapture = new ImageCapture(track)
                                document.querySelector('video').srcObject = mediaStream
                            })
                            .catch((error) => console.log(error))
                    }

                    currentCamera = currentCamera == 'back' ? 'front' : 'back'
                })

                document.getElementById('flash-camera').addEventListener('click', function (e) {
                    log('flash photo')
                    e.preventDefault()
                    e.stopPropagation()

                    if (currentCamera == 'back') {
                        track.applyConstraints({
                            advanced: [{ torch: !flash }]
                        })
                        flash = !flash
                        if (flash) {
                            document.getElementById('flash-camera').classList.remove('bounce')
                        } else {
                            document.getElementById('flash-camera').classList.add('bounce')
                        }
                    }
                })

                document.getElementById('cancel-camera').addEventListener('click', function (e) {
                    log('cancel photo')
                    e.preventDefault()
                    e.stopPropagation()

                    track.stop()
                    self.$store.commit('setCameraActive', false)
                })

                document.getElementById('take-camera').addEventListener('click', function (e) {
                    log('take photo')
                    e.preventDefault()
                    e.stopPropagation()

                    imageCapture
                        .takePhoto()
                        .then((blob) => createImageBitmap(blob))
                        .then((imageBitmap) => {
                            const [newWidth, newHeight] = calculateImgSize(imageBitmap, andyImageLimit.width, andyImageLimit.height)
                            const canvas = document.createElement('canvas')
                            canvas.width = newWidth
                            canvas.height = newHeight
                            const ctx = canvas.getContext('2d')
                            ctx.drawImage(imageBitmap, 0, 0, newWidth, newHeight)
                            canvas.toBlob(
                                (blob) => {
                                    // Handle the compressed image. es. upload or save in local state
                                    // displayFileInfo('Original file', file)
                                    displayFileInfo('Compressed file', blob)
                                    var reader = new FileReader()
                                    reader.readAsDataURL(blob)
                                    reader.onloadend = function () {
                                        var base64data = reader.result
                                        log('base64 image:', base64data)
                                        self.compImage = base64data

                                        self.$store.commit('setCameraActive', false)
                                        track.stop()
                                    }
                                },
                                andyImageLimit.mime,
                                andyImageLimit.quality
                            )

                            // drawCanvas(canvas, imageBitmap)
                        })
                        .catch((error) => console.log(error))
                })

                self.$overlay.hide()
            }, 200)
        },
        capture(e, type) {
            if (process.env.VUE_APP_IS_APP == 'TRUE') {
                e.preventDefault()
                e.stopPropagation()
                var self = this

                var cameraOptions = {
                    destinationType: 1,
                    quality: 50,
                    encodingType: 0, // JEPG
                    targetWidth: andyImageLimit.width,
                    targetHeight: andyImageLimit.height,
                    correctOrientation: true
                }

                this.$popup.confirmUpload({
                    message: this.$t('cordova.plugins.camera.title'),
                    textSaveStart: this.$t('cordova.plugins.camera.text_camera'),
                    textSave: this.$t('cordova.plugins.camera.text_library'),
                    callSaveStart: function () {
                        if (self.$store.getters.getIsAndroid) {
                            self.captureNative()
                        } else {
                            navigator.camera.getPicture(
                                function (imageData) {
                                    getFileContentAsBase64(
                                        imageData,
                                        function (base64Image) {
                                            // Then you'll be able to handle the myimage.png file as base64
                                            log(base64Image)
                                            imageData = base64Image

                                            var bgImg = new Image()
                                            bgImg.onload = function () {
                                                // switch( type ){
                                                //   case 'answer': 	self.compValue = bgImg.src;
                                                //           break;
                                                //   case 'comment':	self.commentImage = bgImg.src;
                                                //           break;
                                                // }
                                                self.compImage = bgImg.src
                                            }
                                            bgImg.src = imageData
                                        },
                                        function (err) {
                                            self.$snackbar.error({ closeable: true, message: self.$t('popup.media.image_empty') })
                                        }
                                    )
                                },
                                function (message) {
                                    log(message)
                                },
                                cameraOptions
                            )
                        }
                    },
                    callSave: function () {
                        cameraOptions.sourceType = 0
                        if (self.$store.getters.getIsAndroid) {
                            self.captureNativeGallery()
                        } else {
                            navigator.camera.getPicture(
                                function (imageData) {
                                    getFileContentAsBase64(
                                        imageData,
                                        function (base64Image) {
                                            // Then you'll be able to handle the myimage.png file as base64
                                            log(base64Image)
                                            imageData = base64Image

                                            var bgImg = new Image()
                                            bgImg.onload = function () {
                                                // switch( type ){
                                                //   case 'answer': 	self.compValue = bgImg.src;
                                                //           break;
                                                //   case 'comment':	self.commentImage = bgImg.src;
                                                //           break;
                                                // }
                                                self.compImage = bgImg.src
                                            }
                                            bgImg.src = imageData
                                        },
                                        function (err) {
                                            self.$snackbar.error({ closeable: true, message: self.$t('popup.media.image_empty') })
                                        }
                                    )
                                },
                                function (message) {
                                    log(message)
                                },
                                cameraOptions
                            )
                        }
                        self.error = ''
                    }
                })
            } else {
                this.imageReload(e)
            }
        }
    },
    watch: {
        editing: {
            handler: function (newVal, oldVal) {
                // var el = document.getElementById('message')
                // this.autoResize(el)
                if (newVal) {
                    this.$refs['message'].focus()
                }
                this.error = ''
            },
            deep: true
        },
        selected: {
            handler: function (newVal, oldVal) {
                // var el = document.getElementById('message')
                // this.autoResize(el)
                if (newVal) {
                    this.$refs['message'].focus()
                }
                this.error = ''
            },
            deep: true
        }
    }
}
</script>
<style lang="scss" scoped>
$note-img-size: 40px;
$note-img-size-w: 40px;
$width_send_note: 40px;
$width_close: 12px;

.post-as {
    @include display-flex();
    @include align-items(center);
    padding-left: 40px;
    height: 48px;
    position: relative;
    top: -50px;
    background-position: left 5px center;
    background-repeat: no-repeat;
    background-size: 35px;
    background-color: $neutro-t80;
    width: 100%;

    span {
        font-family: $text-sbold;
        @include font-size(sm);
        margin-right: 6px;
        color: $inactive-s90;
        margin-left: 5px;
    }
    .select-container {
        width: 200px;
    }

    &.central {
        @include background($image: img('avatar.svg') !important, $position: left 5px center !important, $size: 35px);
    }
    &.hasImage {
        height: 45px;
        top: -118px;
        width: 100%;
    }
    &.selected {
        height: 45px;
        top: -100px;
        width: 100%;
    }
}

.new {
    @include background($color: $neutro-t70);
    @include border-radius(4px);
    border: 1px solid $neutro;
    display: block;
    margin: 0;
    min-height: 60px;
    width: calc(100% - 24px);
    position: absolute;
    bottom: 9px;
    left: 12px;
    z-index: 5;

    &.active {
        border-color: $main-t70;
    }

    .error {
        @include background($color: $error-t70);
        padding: 10px 15px;
        color: $error-s50;
    }

    .reply {
        width: 100%;
        display: block;
        flex: 1;
        padding: 5px 10px;
        @include background($color: $main-t80);
        @include display-flex();
        margin-bottom: 5px;
        @include border-radius(4px);
        max-height: 56px;
        overflow: hidden;

        &:before {
            @include background($image: img('share_aaaaaa.svg'), $size: 20px);
            content: '';
            width: 40px;
            height: 40px;
            display: inline-block;
            margin-right: 0px;
        }

        .content-reply {
            display: inline-block;
            width: calc(100% - 90px);

            .employee {
                font-family: $text-bold;
                margin: 0;
                @include font-size(s);
                color: $neutro-s90;
            }
            .message {
                @include font-size(s);
                margin: 0;
                width: 100%;
                color: $neutro-s90;
                @include text-ellipsis();
            }
        }

        .close {
            @include interaction();
            @include background($image: img('close_main_s20.svg'), $size: 12px, $color: $main-t70);
            @include border-radius(100%);
            width: 40px;
            height: 40px;
            display: inline-block;
            margin: 0;
            margin-left: 10px;

            &:hover {
                background-color: hover($main-t70);
            }
        }
    }

    .editing {
        width: 100%;
        display: block;
        flex: 1;
        padding: 5px 10px;
        @include background($color: $main-t80);
        @include display-flex();
        margin-bottom: 5px;
        @include border-radius(4px);

        &:before {
            @include background($image: img('edit_dark.svg'), $size: 13px, $position: 4px 10px);
            content: '';
            width: 35px;
            height: 35px;
            display: inline-block;
            margin-right: 0px;
        }
        .message {
            @include font-size(s);
            font-family: $text-bold;
            margin: 0;
            width: 100%;
            color: $main-s50;
            padding-top: 0px;
            @include text-ellipsis();
        }

        .close {
            @include interaction();
            @include background($image: img('close_main_s20.svg'), $size: 12px, $color: $main-t70);
            @include border-radius(100%);
            width: 30px;
            height: 30px;
            display: inline-block;
            margin: 0;
            margin-left: 10px;

            &:hover {
                background-color: hover($main-t70);
            }
        }
    }
    .attachment-reply {
        @include border-radius(4px);
        @include display-flex();
        @include background($color: $main-t80);
        width: 100%;
        display: block;
        padding: 5px 10px;
        margin-bottom: 5px;
        height: 67px;
        padding-top: 5px;

        .img-container {
            @include display-flex();
            @include border-radius(2px);
            background-repeat: no-repeat;
            width: 50px;
            height: 50px;
            margin-top: 2px;

            .img {
                @include border-radius(2px);
                background-repeat: no-repeat;
                background-position: center;
                width: 50px;
                height: 50px;
                display: block;
            }

            .delete {
                @include interaction();
                @include background($image: img('close_main_ffffff.svg'), $size: 9px, $color: $neutro-s70, $position: 3px 3px);
                @include border-radius(100%);
                min-width: 15px;
                width: 15px;
                height: 15px;
                display: inline-block;
                margin: 0;
                margin-left: -11px;
                margin-top: -3px;

                &:hover {
                    background-color: hover($neutro-s70);
                }
            }
        }
    }

    .meta {
        @include display-flex();
        @include align-items();
        padding: 10px;

        .attachment {
            @include interaction();
            @include background($color: $neutro, $image: img('attach_dark.svg'), $size: 20px);
            @include border-radius(3px);
            width: $note-img-size !important;
            height: $note-img-size !important;

            &:hover {
                background-color: hover($neutro);
            }
        }

        .input-file {
            @include interaction();
            opacity: 0;
            z-index: 1;
        }

        .message {
            @include background($color: $neutro-t70);
            width: calc(100% - 125px);
            border: none;
            color: $neutro-s90;
            line-height: 18px;
            height: 50px;
            max-height: 90px;
            overflow-y: auto;
            margin-left: 10px !important;
            padding-top: 0px;
            font-family: $text;
            cursor: text;

            &::placeholder {
                padding-top: 0px;
                font-style: normal;
                color: $neutro-s40;
            }
        }

        .close {
            @include interaction();
            @include background($image: img('close_main_neutro_dark.svg'));
            width: $width_close;
            height: 12px;
            margin-left: 4px;
            margin-right: 16px;

            &:hover {
                background-image: img('close_main_s20.svg');
            }
        }

        .send {
            @include interaction();
            @include background($image: img('send_light.svg'), $color: $main, $size: 22px);
            @include border-radius(50%);
            width: $width_send_note;
            height: 40px;

            &:hover {
                background-color: hover($main);
            }
        }
    }
}
</style>

<style lang="scss">
.new {
    .message {
        p{
            u{
                text-decoration: underline;
            }
        }

        ul{
            list-style:circle;
            padding-left: 30px;
        }

        ol{
            list-style:decimal;
            padding-left: 30px;
        }
        textarea {
            margin: 0 !important;
            margin-top: -10px !important;
            min-height: 30px;
            padding: 5px;
            border: 0;
            font-style: normal;
            background-color: transparent;
            cursor: text;

            &:focus,
            &:hover {
                border: 0;
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
.contenedor.portrait {
    .new {
        position: fixed;

        .reply {
            height: auto;
            .content-reply {
                background-color: #d7dae3 !important;
                .employee {
                    @include font-size(xs);
                }
            }
        }
        .message {
            margin-left: 0 !important;
            @include font-size(xs);
            &::placeholder {
                @include font-size(xs);
            }
        }
    }
}
</style>
